// Bootstrap customizations

// Buttons
.btn.icon {
  // align text content
  align-items: stretch;
  & > div {
    display: flex;
    align-items: center;
  }
}

// Buttons with icons paddings and their breakpoint classes
.btn-sm.icon {
  padding-left: 0.25rem;
  padding-right: 0.25rem;

  // ensure spinner size matches button
  .spinner-border.spinner-border-sm {
    width: 1.3125rem;
    height: 1.3125rem;
  }
}

.btn-md.icon {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.dropdown.icon .btn {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.btn-md.icon {
  padding: 0.125rem;

  & svg {
    width: var(--icon-md);
    height: var(--icon-md);
  }
  // ensure spinner size matches button
  .spinner-border.spinner-border-sm {
    width: var(--icon-sm);
    height: var(--icon-sm);
    margin: 0.3435rem;
  }
}

.btn-sm.icon {
  & svg {
    width: var(--icon-sm);
    height: var(--icon-sm);
  }
}

// Import actual bootstrap-lib styles
@import '~bootstrap/scss/bootstrap';

// Custom fills for custom Button classes
@each $color, $value in map-remove($theme-colors, 'light') {
  .btn-#{$color} {
    svg {
      fill: color-contrast($value);
    }
  }
  .btn-outline-#{$color},
  .btn-outline-light-#{$color} {
    svg {
      fill: $value;
    }
  }
  .btn-outline-#{$color}.active,
  .btn-outline-light-#{$color} {
    svg {
      fill: color-contrast($value);
    }
  }
  .btn-outline-#{$color}:hover,
  .btn-outline-light-#{$color}:hover {
    svg {
      fill: color-contrast($value);
    }
  }
}
