@import 'variables';
html,
body,
#root {
  margin: 0;
}

@import './bootstrap.scss';

.half-page {
  height: var(--box-height);
  @include media-breakpoint-up('lg') {
    height: min(var(--page-height), 500px);
  }
}
