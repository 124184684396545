$navbar-light-brand-color: #304376;

:root {
  // Icons
  --icon-sm: 1.3125rem; //21px
  --icon-md: 2rem; //33px
  --icon-lg: 3rem;
  // Elements
  --header-height: calc(2.5rem + 16px); // 2.5 rem with 8px margins)
  --page-height: calc(100vh - var(--header-height));
  --box-height: calc(var(--page-height) / 2);
}
